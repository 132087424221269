import React, { useState } from 'react';
import './Recipes.css';
import { useNavigate } from 'react-router-dom';

const Recipes: React.FC = () => {
    const [query, setQuery] = useState('');
    const [recipes, setRecipes] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const resultsPerPage = 10; // Limit results per page

    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setRecipes([]);

        try {
            const response = await fetch(`/api/searchRecipe?q=${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch recipes. Please try again.');
            }

            const data = await response.json();
            setRecipes(data.results || []);
            setCurrentPage(1); // Reset to the first page on a new search
        } catch (err) {
            console.error(err);
            setError('Error fetching recipes. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const paginatedRecipes = recipes.slice(0, currentPage * resultsPerPage);

    const loadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <div className="recipes">
            <div className="recipes-container">
                <form onSubmit={handleSearch}>
                    <h2>Search for any recipe!</h2>
                    <input
                        type="text"
                        placeholder="Search for recipes..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        required
                    />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </form>

                {error && <p className="error">{error}</p>}

                <div className="recipe-results">
                    {paginatedRecipes.length > 0 ? (
                        paginatedRecipes.map((recipe) => (
                            <div key={recipe.id} className="recipe-card">
                                <h3>{recipe.title}</h3>
                                {recipe.image && <img src={recipe.image} alt={recipe.title} />}
                                <p>
                                    <a
                                        href={`https://spoonacular.com/recipes/${recipe.title}-${recipe.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View Recipe
                                    </a>
                                </p>
                            </div>
                        ))
                    ) : (
                        !loading && <p>No recipes found. Try another search!</p>
                    )}
                </div>

                {recipes.length > paginatedRecipes.length && !loading && (
                    <button className="load-more" onClick={loadMore}>
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
};

export default Recipes;
