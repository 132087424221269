// src/components/Login.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState<{ first_name: string, last_name: string } | null>(null);
    const navigate = useNavigate(); // for routing

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('Something wrong homie');
        try {
            const response = await fetch('http://group30.xyz/api/login', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ Username: username, Password: password }),
            });
      
            const data = await response.json();
      
            if (data.error) {
              setError(data.error);
            } else {
              setUser({ first_name: data.first_name, last_name: data.last_name });
              alert(`Welcome ${data.first_name} ${data.last_name}!`);
              navigate('/recipes'); // Redirect to the Recipes page on successful login
            }
          } catch (error) {
            console.error('Login failed:', error);
            setError('An error occurred. Please try again.');
          }
        };

    return (
        <div className="login">
            <div className='overlay'></div>
            <div className="login-container">
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <label>
                        Username:
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit">Login</button>
                </form>
                <p>
                    <Link to="/forgot-password">Forgot Password?</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
