// src/components/Signup.tsx
import React, { useState } from 'react';
import './Signup.css'; // Optional: add custom styling for the signup page

const Signup: React.FC = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState(''); 
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [firstName, setFirstName] = useState(''); 
    const [lastName, setLastName] = useState('');

    async function doSignup(event: any): Promise<void> {
        event.preventDefault();
        console.log("Signup was called"); 
        console.log("Username:", username);
        console.log("Password:", password);
        console.log("First Name:", firstName);
        console.log("Last Name:", lastName);
        console.log("Email:", email);

        // Replace these with state variables or inputs for your signup form
        let obj = {
            Login: username,
            Password: password,
            FirstName: firstName,
            LastName: lastName, 
            Email: email
        };
        let js = JSON.stringify(obj);
        console.log("Request payload:", js);
    
        try {
            const response = await fetch('http://group30.xyz/api/signup', {
                method: 'POST',
                body: js,
                headers: { 'Content-Type': 'application/json' }
            });
    
            var res = JSON.parse(await response.text());
    
            if (res.id <= 0) {
                console.log('Signup failed. Please try again.');
            } else {
                var user = { firstName: res.firstName, lastName: res.lastName, id: res.id };
                localStorage.setItem('user_data', JSON.stringify(user));
    
                console.log('Signup successful!');
                window.location.href = '/login';
            }
        } catch (error: any) {
            alert(error.toString());
            return;
        }
    };

    return (
        <div className="signup">
            <div className="signup-container">
                <h2>Sign Up</h2>
                <form onSubmit={doSignup}>
                    <label>
                        First Name: 
                        <input
                            type="text"
                            value= {firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required>
                        </input>
                    </label>
                    <label>
                        Last Name: 
                        <input
                            type="text"
                            value= {lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required>
                        </input>
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Username: 
                        <input 
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Repeat Password:
                        <input
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit">Sign Up</button>
                </form>
            </div>
        </div>
       
    );
};

export default Signup;

