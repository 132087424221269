import React from 'react';
import './App.css';
import LandingPage from './components/LandingPage'; // Import the new LandingPage component
import Signup from './components/Signup'; //Imports signup
import Login from './components/Login';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; //imports routing
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Recipes from './components/Recipes'

function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
      {/* Render the LandingPage component */}
      <Route path = "/" element = {<LandingPage />}/>
      <Route path = "/signup" element = {<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/recipes" element={<Recipes />} />
      </Routes>
    </div>
    </Router>
  );
}

export default App;

